@import 'settings/variable';  //themes' colors

//base...
@import 'generic/normalize.scss';
@import 'generic/box-sizing.scss';
@import './base/scaffolding';         // styles of base elements
@import './base/typography';          // base styles of h1-h6, p, span

//components...
@import 'component/btn';
@import './component/card';
@import './component/noty.css';
@import './component/check-box';
@import './component/date-picker';
@import './component/sidebar';
@import './component/edit-table';             // components/table/EditableTable.js ('react-data-grid')
@import './component/topbar';
@import './component/load';
@import './component/form';
@import './component/modal';                  // components/Modal.js ('reactstrap')
@import './offerDetails/offerDetails';
@import './component/react-select';
@import './component/pagination';             // components/Pagination.js ('reactstrap')
@import './component/table';                  // components/Table.js ('reactstrap')
@import './dashboard/dashboard';
@import './offers/offers';
@import './reports/reportsCharts';
@import './reports/offerReportTable';
@import './referral/referral';

//pages...
@import 'containers/account';

//objects...
@import 'objects/layout.scss';

.react-select__input__control {
  height: 20px !important;
  border-radius: 0 !important;
}

.react-select__input__indicator-separator {
  margin-top: 0 !important;
}

.react-select__input__indicator {
  padding-top: 0 !important;
}

.react-select__input__single-value {
  margin-top: -4px !important;
}

.react-select__input__placeholder {
  margin-top: -4px !important;
}

.no-margin {
  margin: 0 !important; }

.no-margin-top {
  margin-top: 0 !important; }

.no-margin-bottom {
  margin-bottom: 0 !important; }

.no-margin-left {
  margin-left: 0 !important; }

.no-margin-right {
  margin-right: 0 !important; }

.m-5 {
  margin: 5px !important; }

.m-10 {
  margin: 10px !important; }

.m-15 {
  margin: 15px !important; }

.m-20 {
  margin: 20px !important; }

.mt-5 {
  margin-top: 5px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mb-5 {
  margin-bottom: 5px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.ml-5 {
  margin-left: 5px !important; }

.ml-10 {
  margin-left: 10px !important; }

.ml--10 {
  margin-left: -10px !important; }

.ml-15 {
  margin-left: 15px !important; }

.ml-20 {
  margin-left: 20px !important; }

.mr-5 {
  margin-right: 5px !important; }

.mr-10 {
  margin-right: 10px !important; }

.mr-15 {
  margin-right: 15px !important; }

.mr-20 {
  margin-right: 20px !important; }

.m--5 {
  margin: -5px !important; }

.m--10 {
  margin: -10px !important; }

.m--15 {
  margin: -15px !important; }

.m--20 {
  margin: -20px !important; }

.mt--5 {
  margin-top: -5px !important; }

.mt--10 {
  margin-top: -10px !important; }

.mt--15 {
  margin-top: -15px !important; }

.mt--20 {
  margin-top: -20px !important; }

.mb--5 {
  margin-bottom: -5px !important; }

.mb--10 {
  margin-bottom: -10px !important; }

.mb--15 {
  margin-bottom: -15px !important; }

.mb--20 {
  margin-bottom: -20px !important; }

.ml--5 {
  margin-left: -5px !important; }

.ml--10 {
  margin-left: -10px !important; }

.ml--15 {
  margin-left: -15px !important; }

.ml--20 {
  margin-left: -20px !important; }

.mr--5 {
  margin-right: -5px !important; }

.mr--10 {
  margin-right: -10px !important; }

.mr--15 {
  margin-right: -15px !important; }

.mr--20 {
  margin-right: -20px !important; }

.no-padding {
  padding: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding-left {
  padding-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.p-5 {
  padding: 5px !important; }

.p-10 {
  padding: 10px !important; }

.p-15 {
  padding: 15px !important; }

.p-20 {
  padding: 20px !important; }

.pt-5 {
  padding-top: 5px !important; }

.pt-10 {
  padding-top: 10px !important; }

.pt-15 {
  padding-top: 15px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pb-5 {
  padding-bottom: 5px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.pb-15 {
  padding-bottom: 15px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.pl-5 {
  padding-left: 5px !important; }

.pl-10 {
  padding-left: 10px !important; }

.pl-15 {
  padding-left: 15px !important; }

.pl-20 {
  padding-left: 20px !important; }

.pr-5 {
  padding-right: 5px !important; }

.pr-10 {
  padding-right: 10px !important; }

.pr-15 {
  padding-right: 15px !important; }

.pr-20 {
  padding-right: 20px !important; }

.emailSignUpDanger input[name='email']{
  border-color: #ff4861;
}

.passwordSignUpDanger input[name='password']{
  border-color: #ff4861;
}

.custom-link-element {
  &:hover {
    text-decoration: underline;
  }
}

.profile-mail {
  display: flex;
  flex-direction: column;
}

.profile-mail-custom {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-alias-name {
  position: absolute;
  bottom: 4px;
  left: 0;
  font-size: 14px;
}

.banners-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px 20px 20px;
}

.banners-img-container {
  margin: 10px;
}
