.referral-program {
  p.fz-15 {
    font-size: 15px;
  }
  p.fz-19 {
    font-size: 19px;
  }
  .image-rewards-container {
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.heading-font-size {
  .fz-24 {
    font-size: 24px !important;
  }
  .fz-21 {
    font-size: 21px !important;
  }
}

.share-link-list {
  display: flex;
  list-style-type: none;
  padding: 0;
  li {
    width: 35px;
    height: 35px;
    border-radius: 35px;
    margin-right: 10px;
  }
  .facebook-item {
    background-color: #3b5998;
  }
  .twitter-item {
    background-color: #00aced;
  }
  .linkedin-item {
    background-color: #0077b5;
  }
  .share-link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-facebook-container, .icon-twitter-container, .icon-linkedin-container {
    font-size: 20px;
    i {
      color: white !important;
      font-size: 20px !important;
    }
  }

  .icon-twitter-container {
    i {
      color: white !important;
      font-size: 14px !important;
    }
  }
  .icon-linkedin-container {
    i {
      color: white !important;
      font-size: 17px !important;
    }
  }
}

.progress-bar-heading {
  display: flex;
  flex-direction: row;
  padding-right: 40px;
  padding-left: 40px;
  align-items: center;
  h5 {
    color: #2196f3;
    text-transform: uppercase;
  }
  hr {
    width: 30%;
    border-color: #2196f3;
  }
}

.progress-bar-container {
  width: 65%;
  margin: 0 auto;
  margin-bottom: 40px;
}

.progress-bar-description {
  margin: 30px auto;
}

.indexedStep {
  color: white;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: rgba(0, 116, 217, 1);
}
.customize-facebook-icon {
  height: 38px;
  width: 38px;
  color: #3b5998;
}
.customize-twitter-icon {
  height: 38px;
  width: 38px;
  color: #00aced;
}
.customize-linkedin-icon {
  margin-bottom: 5px;
  color: #ffffff;
}
