.charts {
}

.charts.charts-collapse {
  animation: animation-charts-collapse .5s ease-in forwards;
}

.charts.charts-expand {
  animation: animation-charts-expand .5s ease-in 0s forwards,animation-charts-opacity 1s ease-in .7s forwards;
}

@keyframes animation-charts-collapse {
  from {
    height: 180px;
    opacity: 1;
  }
  to {
    height: 0;
    opacity: 0;
  }
}

@keyframes animation-charts-expand {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 180px;
    opacity: 0;
  }
}

@keyframes animation-charts-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
