.datepicker_custom {
  line-height: 45px;
  margin-bottom: 0 !important;
  .date-picker {
    min-height: 42px;
  }
  .react-datepicker-wrapper {
    & input[type='text'] {
      min-height: 42px;
    }
  }
}

.custom-label-switch {
  margin-bottom: 0;
  min-height: 42px;
}

.custom-data-offer-table {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: 0px !important;
  .rt-thead.-header {
    box-shadow: none !important;
    border-bottom: 1px solid #eff1f5 !important;
    color: #646777;
    height: 44px !important;
    .rt-th {
      margin: 0px !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      transform: none !important;
      background: transparent !important;
      border: none !important;
      padding: 0 10px !important;
      font-weight: bold !important;
      .rt-resizable-header-content {
        transform: translateY(-50%) !important;
        top: 50% !important;
        position: absolute !important;
        span {
          text-wrap: wrap;
        }
      }
    }
  }
  .rt-tbody {
    .rt-tr-group {
      border-bottom: 0px !important;
      .rt-tr {
        & > .rt-td {
          line-height: 44px !important;
          margin-left: 0px !important;
          //position: relative !important;
          //top: 50% !important;
          //transform: translateY(-50%) !important;
        }
      }
    }
    .rt-tr.hidden {
      display: none !important;
    }
    .rt-tr {
      .hidden {
        display: none !important;
      }
      .rt-td {
        height: 44px !important;
        left: 0px !important;
        background: transparent !important;
        border: none !important;
        padding: 0 10px !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        & > div {
          margin-left: 0px !important;
          position: relative !important;
          top: 50% !important;
          transform: translateY(-50%) !important;
        }
      }
      &.current {
        background-color: #FFF59D;
      }
      &.custom-odd {
        background-color: #f9f9f9;
      }
      &.custom-even {
        background-color: #ffffff;
      }
      &.green {
        background-color: rgb(200, 230, 201);
        .bolder {
          font-weight: bold;
          color: #388E3C;
        }
      }
      &.lightgreen {
        background-color: rgba(200, 230, 201, 0.44);
        .bolder {
          font-weight: bold;
          color: #388E3C
        }
      }
      &.lightyellow {
        background-color: rgba(230, 216, 80, 0.44);
        .bolder {
          font-weight: bold;
          color: rgba(165, 165, 26, 0.83);
        }
      }
      &.red {
        background-color: rgba(255, 204, 188, 0.3);
        .bolder {
          font-weight: bold;
          color: darkred
        }
      }
      &.bg-info {
        &:hover {
          background-color: #4DD0E1;
        }
      }
      &:hover {
        @include themify($themes) {
          background-color: themed('colorActiveDark');
        }
        .hidden-action {
          display: inline;
        }
      }
    }
  }
  .rt-td {
    font-size: 13px;
    padding: 4px;
    .hidden-action {
      display: none;
    }
  }
  .rt-tfoot {
    box-shadow: none !important;
    background-color: #f5f5f5 !important;
    font-weight: bold !important;
    text-align: right !important;
    .rt-tr {
      & > .rt-td {
        text-align: left !important;
        line-height: 44px !important;
        margin-left: 0px !important;
        //position: relative !important;
        //top: 50% !important;
        //transform: translateY(-50%) !important;
      }
    }
    .rt-tr {
      .rt-td {
        text-align: left !important;
        height: 44px !important;
        left: 0px !important;
        background: transparent !important;
        border: none !important;
        padding: 0 10px !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;

        & > div {
          margin-left: 0px !important;
          position: relative !important;
          top: 50% !important;
          transform: translateY(-50%) !important;
        }
      }
    }
  }
}

.padded-list {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    &:hover {
      background-color: #efefef;
    }
    a {
      display: block;
      padding: 1em 0.5em;
      border-bottom: 1px solid #ddd;
    }
  }
  li:last-of-type a {
    border-bottom: none;
  }
}

.footer {
  ul, p {
    margin: 0
  }
}
