.noty_layout_mixin, #noty_layout__top, #noty_layout__topLeft, #noty_layout__topCenter, #noty_layout__topRight, #noty_layout__bottom, #noty_layout__bottomLeft, #noty_layout__bottomCenter, #noty_layout__bottomRight, #noty_layout__center, #noty_layout__centerLeft, #noty_layout__centerRight {
  position: fixed;
  margin: 0;
  padding: 0;
  z-index: 9999999;
  -webkit-transform: translateZ(0) scale(1, 1);
          transform: translateZ(0) scale(1, 1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  filter: blur(0);
  -webkit-filter: blur(0);
  max-width: 90%; }

#noty_layout__top {
  top: 0;
  left: 5%;
  width: 90%; }

#noty_layout__topLeft {
  top: 20px;
  left: 20px;
  width: 325px; }

#noty_layout__topCenter {
  top: 5%;
  left: 50%;
  width: 325px;
  -webkit-transform: translate(-webkit-calc(-50% - .5px)) translateZ(0) scale(1, 1);
          transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1); }

#noty_layout__topRight {
  top: 20px;
  right: 20px;
  width: 325px; }

#noty_layout__bottom {
  bottom: 0;
  left: 5%;
  width: 90%; }

#noty_layout__bottomLeft {
  bottom: 20px;
  left: 20px;
  width: 325px; }

#noty_layout__bottomCenter {
  bottom: 5%;
  left: 50%;
  width: 325px;
  -webkit-transform: translate(-webkit-calc(-50% - .5px)) translateZ(0) scale(1, 1);
          transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1); }

#noty_layout__bottomRight {
  bottom: 20px;
  right: 20px;
  width: 325px; }

#noty_layout__center {
  top: 50%;
  left: 50%;
  width: 325px;
  -webkit-transform: translate(-webkit-calc(-50% - .5px), -webkit-calc(-50% - .5px)) translateZ(0) scale(1, 1);
          transform: translate(calc(-50% - .5px), calc(-50% - .5px)) translateZ(0) scale(1, 1); }

#noty_layout__centerLeft {
  top: 50%;
  left: 20px;
  width: 325px;
  -webkit-transform: translate(0, -webkit-calc(-50% - .5px)) translateZ(0) scale(1, 1);
          transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1); }

#noty_layout__centerRight {
  top: 50%;
  right: 20px;
  width: 325px;
  -webkit-transform: translate(0, -webkit-calc(-50% - .5px)) translateZ(0) scale(1, 1);
          transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1); }

.noty_progressbar {
  display: none; }

.noty_has_timeout.noty_has_progressbar .noty_progressbar {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  background-color: #646464;
  opacity: 0.2;
  filter: alpha(opacity=10); }

.noty_bar {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate(0, 0) translateZ(0) scale(1, 1);
  -ms-transform: translate(0, 0) scale(1, 1);
      transform: translate(0, 0) scale(1, 1);
  -webkit-font-smoothing: subpixel-antialiased;
  overflow: hidden; }

.noty_effects_open {
  opacity: 0;
  -webkit-transform: translate(50%);
      -ms-transform: translate(50%);
          transform: translate(50%);
  -webkit-animation: noty_anim_in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
          animation: noty_anim_in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.noty_effects_close {
  -webkit-animation: noty_anim_out 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
          animation: noty_anim_out 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.noty_fix_effects_height {
  -webkit-animation: noty_anim_height 75ms ease-out;
          animation: noty_anim_height 75ms ease-out; }

.noty_close_with_click {
  cursor: pointer; }

.noty_close_button {
  position: absolute;
  top: 2px;
  right: 2px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }

.noty_close_button:hover {
  background-color: rgba(0, 0, 0, 0.1); }

.noty_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 10000;
  opacity: .3;
  left: 0;
  top: 0; }

.noty_modal.noty_modal_open {
  opacity: 0;
  -webkit-animation: noty_modal_in .3s ease-out;
          animation: noty_modal_in .3s ease-out; }

.noty_modal.noty_modal_close {
  -webkit-animation: noty_modal_out .3s ease-out;
          animation: noty_modal_out .3s ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

@-webkit-keyframes noty_modal_in {
  100% {
    opacity: .3; } }

@keyframes noty_modal_in {
  100% {
    opacity: .3; } }

@-webkit-keyframes noty_modal_out {
  100% {
    opacity: 0; } }

@keyframes noty_modal_out {
  100% {
    opacity: 0; } }

@keyframes noty_modal_out {
  100% {
    opacity: 0; } }

@-webkit-keyframes noty_anim_in {
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
    opacity: 1; } }

@keyframes noty_anim_in {
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
    opacity: 1; } }

@-webkit-keyframes noty_anim_out {
  100% {
    -webkit-transform: translate(50%);
            transform: translate(50%);
    opacity: 0; } }

@keyframes noty_anim_out {
  100% {
    -webkit-transform: translate(50%);
            transform: translate(50%);
    opacity: 0; } }

@-webkit-keyframes noty_anim_height {
  100% {
    height: 0; } }

@keyframes noty_anim_height {
  100% {
    height: 0; } }

.noty_theme__relax.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  border-radius: 2px;
  position: relative; }
  .noty_theme__relax.noty_bar .noty_body {
    padding: 10px; }
  .noty_theme__relax.noty_bar .noty_buttons {
    border-top: 1px solid #e7e7e7;
    padding: 5px 10px; }

.noty_theme__relax.noty_type__alert,
.noty_theme__relax.noty_type__notification {
  background-color: #fff;
  border: 1px solid #dedede;
  color: #444; }

.noty_theme__relax.noty_type__warning {
  background-color: #FFEAA8;
  border: 1px solid #FFC237;
  color: #826200; }
  .noty_theme__relax.noty_type__warning .noty_buttons {
    border-color: #dfaa30; }

.noty_theme__relax.noty_type__error {
  background-color: #FF8181;
  border: 1px solid #e25353;
  color: #FFF; }
  .noty_theme__relax.noty_type__error .noty_buttons {
    border-color: darkred; }

.noty_theme__relax.noty_type__info,
.noty_theme__relax.noty_type__information {
  background-color: #78C5E7;
  border: 1px solid #3badd6;
  color: #FFF; }
  .noty_theme__relax.noty_type__info .noty_buttons,
  .noty_theme__relax.noty_type__information .noty_buttons {
    border-color: #0B90C4; }

.noty_theme__relax.noty_type__success {
  background-color: #BCF5BC;
  border: 1px solid #7cdd77;
  color: darkgreen; }
  .noty_theme__relax.noty_type__success .noty_buttons {
    border-color: #50C24E; }

.noty_theme__metroui.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.298039) 0 0 5px 0; }
  .noty_theme__metroui.noty_bar .noty_progressbar {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    background-color: #000;
    opacity: 0.2;
    filter: alpha(opacity=20); }
  .noty_theme__metroui.noty_bar .noty_body {
    padding: 1.25em;
    font-size: 14px; }
  .noty_theme__metroui.noty_bar .noty_buttons {
    padding: 0 10px .5em 10px; }

.noty_theme__metroui.noty_type__alert,
.noty_theme__metroui.noty_type__notification {
  background-color: #fff;
  color: #1d1d1d; }

.noty_theme__metroui.noty_type__warning {
  background-color: #FA6800;
  color: #fff; }

.noty_theme__metroui.noty_type__error {
  background-color: #CE352C;
  color: #FFF; }

.noty_theme__metroui.noty_type__info,
.noty_theme__metroui.noty_type__information {
  background-color: #1BA1E2;
  color: #FFF; }

.noty_theme__metroui.noty_type__success {
  background-color: #60A917;
  color: #fff; }

.noty_theme__mint.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  border-radius: 2px;
  position: relative; }
  .noty_theme__mint.noty_bar .noty_body {
    padding: 10px;
    font-size: 14px; }
  .noty_theme__mint.noty_bar .noty_buttons {
    padding: 10px; }

.noty_theme__mint.noty_type__alert,
.noty_theme__mint.noty_type__notification {
  background-color: #fff;
  border-bottom: 1px solid #D1D1D1;
  color: #2F2F2F; }

.noty_theme__mint.noty_type__warning {
  background-color: #FFAE42;
  border-bottom: 1px solid #E89F3C;
  color: #fff; }

.noty_theme__mint.noty_type__error {
  background-color: #DE636F;
  border-bottom: 1px solid #CA5A65;
  color: #fff; }

.noty_theme__mint.noty_type__info,
.noty_theme__mint.noty_type__information {
  background-color: #7F7EFF;
  border-bottom: 1px solid #7473E8;
  color: #fff; }

.noty_theme__mint.noty_type__success {
  background-color: #AFC765;
  border-bottom: 1px solid #A0B55C;
  color: #fff; }

.noty_theme__sunset.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  border-radius: 2px;
  position: relative; }
  .noty_theme__sunset.noty_bar .noty_body {
    padding: 10px;
    font-size: 14px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); }
  .noty_theme__sunset.noty_bar .noty_buttons {
    padding: 10px; }

.noty_theme__sunset.noty_type__alert,
.noty_theme__sunset.noty_type__notification {
  background-color: #073B4C;
  color: #fff; }
  .noty_theme__sunset.noty_type__alert .noty_progressbar,
  .noty_theme__sunset.noty_type__notification .noty_progressbar {
    background-color: #fff; }

.noty_theme__sunset.noty_type__warning {
  background-color: #FFD166;
  color: #fff; }

.noty_theme__sunset.noty_type__error {
  background-color: #EF476F;
  color: #fff; }
  .noty_theme__sunset.noty_type__error .noty_progressbar {
    opacity: .4; }

.noty_theme__sunset.noty_type__info,
.noty_theme__sunset.noty_type__information {
  background-color: #118AB2;
  color: #fff; }
  .noty_theme__sunset.noty_type__info .noty_progressbar,
  .noty_theme__sunset.noty_type__information .noty_progressbar {
    opacity: .6; }

.noty_theme__sunset.noty_type__success {
  background-color: #06D6A0;
  color: #fff; }

.noty_theme__bootstrap-v3.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;
  border-radius: 4px; }
  .noty_theme__bootstrap-v3.noty_bar .noty_body {
    padding: 15px; }
  .noty_theme__bootstrap-v3.noty_bar .noty_buttons {
    padding: 10px; }
  .noty_theme__bootstrap-v3.noty_bar .noty_close_button {
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
    background: transparent; }
  .noty_theme__bootstrap-v3.noty_bar .noty_close_button:hover {
    background: transparent;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: .5; }

.noty_theme__bootstrap-v3.noty_type__alert,
.noty_theme__bootstrap-v3.noty_type__notification {
  background-color: #fff;
  color: inherit; }

.noty_theme__bootstrap-v3.noty_type__warning {
  background-color: #fcf8e3;
  color: #8a6d3b;
  border-color: #faebcc; }

.noty_theme__bootstrap-v3.noty_type__error {
  background-color: #f2dede;
  color: #a94442;
  border-color: #ebccd1; }

.noty_theme__bootstrap-v3.noty_type__info,
.noty_theme__bootstrap-v3.noty_type__information {
  background-color: #d9edf7;
  color: #31708f;
  border-color: #bce8f1; }

.noty_theme__bootstrap-v3.noty_type__success {
  background-color: #dff0d8;
  color: #3c763d;
  border-color: #d6e9c6; }

.noty_theme__bootstrap-v4.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;
  border-radius: .25rem; }
  .noty_theme__bootstrap-v4.noty_bar .noty_body {
    padding: .75rem 1.25rem; }
  .noty_theme__bootstrap-v4.noty_bar .noty_buttons {
    padding: 10px; }
  .noty_theme__bootstrap-v4.noty_bar .noty_close_button {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .5;
    background: transparent; }
  .noty_theme__bootstrap-v4.noty_bar .noty_close_button:hover {
    background: transparent;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: .75; }

.noty_theme__bootstrap-v4.noty_type__alert,
.noty_theme__bootstrap-v4.noty_type__notification {
  background-color: #fff;
  color: inherit; }

.noty_theme__bootstrap-v4.noty_type__warning {
  background-color: #fcf8e3;
  color: #8a6d3b;
  border-color: #faebcc; }

.noty_theme__bootstrap-v4.noty_type__error {
  background-color: #f2dede;
  color: #a94442;
  border-color: #ebccd1; }

.noty_theme__bootstrap-v4.noty_type__info,
.noty_theme__bootstrap-v4.noty_type__information {
  background-color: #d9edf7;
  color: #31708f;
  border-color: #bce8f1; }

.noty_theme__bootstrap-v4.noty_type__success {
  background-color: #dff0d8;
  color: #3c763d;
  border-color: #d6e9c6; }

.noty_theme__semanticui.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;
  font-size: 1em;
  border-radius: .28571429rem;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.22) inset, 0 0 0 0 transparent; }
  .noty_theme__semanticui.noty_bar .noty_body {
    padding: 1em 1.5em;
    line-height: 1.4285em; }
  .noty_theme__semanticui.noty_bar .noty_buttons {
    padding: 10px; }

.noty_theme__semanticui.noty_type__alert,
.noty_theme__semanticui.noty_type__notification {
  background-color: #f8f8f9;
  color: rgba(0, 0, 0, 0.87); }

.noty_theme__semanticui.noty_type__warning {
  background-color: #fffaf3;
  color: #573a08;
  box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent; }

.noty_theme__semanticui.noty_type__error {
  background-color: #fff6f6;
  color: #9f3a38;
  box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent; }

.noty_theme__semanticui.noty_type__info,
.noty_theme__semanticui.noty_type__information {
  background-color: #f8ffff;
  color: #276f86;
  box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent; }

.noty_theme__semanticui.noty_type__success {
  background-color: #fcfff5;
  color: #2c662d;
  box-shadow: 0 0 0 1px #a3c293 inset, 0 0 0 0 transparent; }

.noty_theme__nest.noty_bar {
  margin: 0 0 15px 0;
  overflow: hidden;
  border-radius: 2px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.098039) 5px 4px 10px 0; }
  .noty_theme__nest.noty_bar .noty_body {
    padding: 10px;
    font-size: 14px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); }
  .noty_theme__nest.noty_bar .noty_buttons {
    padding: 10px; }

.noty_layout .noty_theme__nest.noty_bar {
  z-index: 5; }

.noty_layout .noty_theme__nest.noty_bar:nth-child(2) {
  position: absolute;
  top: 0;
  margin-top: 4px;
  margin-right: -4px;
  margin-left: 4px;
  z-index: 4;
  width: 100%; }

.noty_layout .noty_theme__nest.noty_bar:nth-child(3) {
  position: absolute;
  top: 0;
  margin-top: 8px;
  margin-right: -8px;
  margin-left: 8px;
  z-index: 3;
  width: 100%; }

.noty_layout .noty_theme__nest.noty_bar:nth-child(4) {
  position: absolute;
  top: 0;
  margin-top: 12px;
  margin-right: -12px;
  margin-left: 12px;
  z-index: 2;
  width: 100%; }

.noty_layout .noty_theme__nest.noty_bar:nth-child(5) {
  position: absolute;
  top: 0;
  margin-top: 16px;
  margin-right: -16px;
  margin-left: 16px;
  z-index: 1;
  width: 100%; }

.noty_layout .noty_theme__nest.noty_bar:nth-child(n+6) {
  position: absolute;
  top: 0;
  margin-top: 20px;
  margin-right: -20px;
  margin-left: 20px;
  z-index: -1;
  width: 100%; }

#noty_layout__bottomLeft .noty_theme__nest.noty_bar:nth-child(2),
#noty_layout__topLeft .noty_theme__nest.noty_bar:nth-child(2) {
  margin-top: 4px;
  margin-left: -4px;
  margin-right: 4px; }

#noty_layout__bottomLeft .noty_theme__nest.noty_bar:nth-child(3),
#noty_layout__topLeft .noty_theme__nest.noty_bar:nth-child(3) {
  margin-top: 8px;
  margin-left: -8px;
  margin-right: 8px; }

#noty_layout__bottomLeft .noty_theme__nest.noty_bar:nth-child(4),
#noty_layout__topLeft .noty_theme__nest.noty_bar:nth-child(4) {
  margin-top: 12px;
  margin-left: -12px;
  margin-right: 12px; }

#noty_layout__bottomLeft .noty_theme__nest.noty_bar:nth-child(5),
#noty_layout__topLeft .noty_theme__nest.noty_bar:nth-child(5) {
  margin-top: 16px;
  margin-left: -16px;
  margin-right: 16px; }

#noty_layout__bottomLeft .noty_theme__nest.noty_bar:nth-child(n+6),
#noty_layout__topLeft .noty_theme__nest.noty_bar:nth-child(n+6) {
  margin-top: 20px;
  margin-left: -20px;
  margin-right: 20px; }

.noty_theme__nest.noty_type__alert,
.noty_theme__nest.noty_type__notification {
  background-color: #073B4C;
  color: #fff; }
  .noty_theme__nest.noty_type__alert .noty_progressbar,
  .noty_theme__nest.noty_type__notification .noty_progressbar {
    background-color: #fff; }

.noty_theme__nest.noty_type__warning {
  background-color: #FFD166;
  color: #fff; }

.noty_theme__nest.noty_type__error {
  background-color: #EF476F;
  color: #fff; }
  .noty_theme__nest.noty_type__error .noty_progressbar {
    opacity: .4; }

.noty_theme__nest.noty_type__info,
.noty_theme__nest.noty_type__information {
  background-color: #118AB2;
  color: #fff; }
  .noty_theme__nest.noty_type__info .noty_progressbar,
  .noty_theme__nest.noty_type__information .noty_progressbar {
    opacity: .6; }

.noty_theme__nest.noty_type__success {
  background-color: #06D6A0;
  color: #fff; }

/*# sourceMappingURL=noty.css.map*/