.cell-container {
  border: 1px solid #dddddd;
  border-radius: 3px;
  & > .react-select {
    min-height: 36px !important;
  }
}

.metricA-container {
  margin: 4px;
  margin-left: 0;
}

.metricB-container {
  margin: 4px;
}

.metricC-container {
  margin: 4px;
}

.metricD-container {
  margin: 4px;
  margin-right: 0;
}

.panel-body-container {
  & > .metricA-cell {
    padding-left: 0;
    padding-right: 7px;
  }

  & > .metricB-cell {
    padding-left: 7px;
    padding-right: 7px;
  }

  & > .metricC-cell {
    padding-left: 7px;
    padding-right: 7px;
  }

  & > .metricD-cell {
    padding-left: 7px;
    padding-right: 10px;
  }
}

.row-container {
  & > .select-container {
    min-height: 32px !important;
    border: 0;
    & > .react-select__control {
      min-height: 32px !important;
      border: 0;
      .react-select__input {
        height: 26px !important;
      }
      .react-select__clear-indicator {
        padding-right: 2px !important;
      }
      .react-select__dropdown-indicator {
        padding-left: 2px !important;
      }
    }
    & > .react-select__control:hover {
      box-shadow: none;
      cursor: pointer;
    }
    &:focus {
      outline: 0;
    }
  }
  & > .react-select.is-focused:not(.is-open) {
    & > .react-select__control {
      border: 0;
      box-shadow: none;
    }
  }
}

.row-container {
  & > .select-container-first {
  }
}

.text-bold {
  font-weight: 700;
}

.text-center {
  text-align: center;
  font-size: 12px;
  line-height: 36px;
}

.legend {
  height: 34px;
  line-height: 28px;
}

.biggest-changes-text-separator {
  width: 10px !important;
  text-align: center !important;
}

.biggest-change-label {
  max-width: 110px !important;
}
.biggest-change-selected-period {
  min-width: 200px !important;
  font-weight: bold !important;
}

.show-details-style {
  font-size: 11px;
  padding: 0 0 0 30px;
}

.biggest-change-table {
  border-collapse: collapse;
}

.biggest-change-thead {
  box-shadow: 0 13px 19px -7px rgba(0, 0, 0, 0.15);
  th {
    border: 1px solid rgba(0, 0, 0, 0.05);
    padding: 8px !important;
  }
}

.biggest-change-tbody  {
  td {
    border: 1px solid rgba(0, 0, 0, 0.05);
    padding: 4px 4px 4px 8px;
  }
  tr:nth-child(even) {
    & > td {
      padding: 0;
    }
  }
}

.periods-select-row {
  margin: 0 0 20px 0 !important;

}

.custom-table-collapse {
  padding: 0 !important;
  display: none !important;
}

.expand {
  display: block !important;
}

.dif-value {
  min-width: 100px !important;
}

.hide-show-details-container {
  position: relative;
}

.dif-value-positive {
  color: green;
  &:after {
    content: '\2B06';
    color: green;
  }
}

.dif-value-negative {
  color: red;
  &:after {
    content: '\2B07';
    color: red;
  }
}

.rules-circle {
  display: inline-block;
  position: absolute;
  right: 0;
  margin-right: 20px;
  &.active:hover {
    cursor: pointer;
  }
  &.disabled:hover {
    cursor: not-allowed;
  }
}

.classNameDif {
  color: red;
}

.styleButton {
  &:hover {
    color: #a80000;
  }
}

.filter__by-offer {
  display: flex;
  .label {
    min-width: 100px;
    text-align: start;
    line-height: 42px;
    margin-bottom: 0;
  }
}
